import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { SELECT_BANK } from '../constants/Urls';
import axios, { AxiosResponse } from 'axios';

export interface SelectBankParams {
    name: string;
    country: string;
    id: string;
    companyName: string;
  }
  
export interface SelectBankResponse {
    redirectUrl: string;
  }
  
  const SelectBank = async ({ name, country, id, companyName }: SelectBankParams) => {
    const { data } = await axios.post<any, AxiosResponse<SelectBankResponse>>(SELECT_BANK, {name, country, id, companyName});
    return data;
  };

const useBankSelect = () :[(parameters: SelectBankParams) => void, boolean, boolean, string] => {

    const [result, setResult] = useState<string>("");
    const { mutate, isLoading, isError } = useMutation<SelectBankResponse, Error, SelectBankParams>(
        SelectBank,
        {
          onSuccess: (data: SelectBankResponse) => {
            const { redirectUrl } = data;    
            console.log(redirectUrl);

            setResult(redirectUrl);

            // without 'back' support
            window.location.replace(redirectUrl);
            
            //with 'back support'
            //window.location.assign(redirectUrl);

            // redirect to the new task we just created
            //history.push(GetDueDateRouteUrl('DEADLINEPAGE', createdTaskId));
          },
        }
      );
      
    return [mutate, isLoading, isError, result]
};

export default useBankSelect;
  

