import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { VALIDATE_ACCOUNT } from '../constants/Urls';
import axios, { AxiosResponse } from 'axios';

export interface AccountVerificationParams {
    code: string;
    identifier: string;
  }
  
export interface VerifyAccountResponse {
    isSuccess?: boolean;
  }
  
  const VerifyAccount = async ({ code, identifier }: AccountVerificationParams) => {
    const { data } = await axios.post<any, AxiosResponse<VerifyAccountResponse>>(VALIDATE_ACCOUNT, {code, identifier});
    return data;
  };

const useAccountVerification = () :[(parameters: AccountVerificationParams) => void, boolean, boolean, boolean | undefined] => {

    const [result, setResult] = useState<boolean | undefined>(false);
    const { mutate, isLoading, isError } = useMutation<VerifyAccountResponse, Error, AccountVerificationParams>(
        VerifyAccount,
        {
          onSuccess: (data: VerifyAccountResponse) => {
            const { isSuccess } = data;    
            console.log(isSuccess);

            setResult(isSuccess);
          },
        }
      );
      
    return [mutate, isLoading, isError, result]
};

export default useAccountVerification;
  

