import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import logo from '../images/FL_logo.png';

export default function PageHeader() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ backgroundColor: '#24262b' }}>
        <Toolbar>
          <img src={logo} alt="Logo" style={{ marginRight: 2, width: 200, height: 30 }} />
        </Toolbar>
      </AppBar>
    </Box>
  );
}
