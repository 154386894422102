import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { styled } from '@mui/system';

const NameComponent = styled('span')({
  textAlign: 'left',
  fontSize: 'small',
  display: 'block',
});

const AddressComponent = styled('span')({
  textAlign: 'right',
  fontSize: 'small',
  display: 'block',
});

export default function PageFooter() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ backgroundColor: '#24262b', position: 'fixed', bottom: 0, width: '100%' }}>
        <Toolbar>
          <div style={{ marginRight: 2 }}>
            <NameComponent>Finance Link Oy</NameComponent>
            <NameComponent>Mannerheiminaukio 1 B, 00100 Helsinki | Y-tunnus: 2006432-7</NameComponent>
          </div>
          <div style={{ flexGrow: 1 }}></div>
          <div>
            <AddressComponent>Asiakastuki</AddressComponent>
            <AddressComponent>puh. 09 7744 740</AddressComponent>
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
