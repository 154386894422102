import React, { useState } from 'react';
import { CircularProgress } from '@mui/material';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import useAccountVerification from '../hooks/useAccountVerification';
import { useDebounce } from '../hooks/useDebounce';

export default function VerificationPage() {
  const search = window.location.search;
  const params = new URLSearchParams(search);

  const [mutate, isLoading, isError, result] = useAccountVerification();

  const [parsedCode, setParsedCode] = useState<string>('');
  const [parsedState, setParsedState] = useState<string>('');

  // state=b897af08-3941-425a-a051-602b63f7d82a&code=4ce9aa2e-43a5-479a-94d7-9d7ec3b3fdf8
  const state = params.get('state');
  const code = params.get('code');

  const debouncedState = useDebounce(state, 300);
  const debouncedCode = useDebounce(code, 300);

  React.useEffect(() => {
    if (debouncedState !== null && debouncedCode !== null) {
      setParsedCode(debouncedCode);
      setParsedState(debouncedState);
    }
  }, [debouncedState, debouncedCode]);

  React.useEffect(() => {
    if (parsedCode && parsedState) {
      mutate({ code: parsedCode, identifier: parsedState });
    }
  }, [mutate, parsedCode, parsedState]); // hack to prevent mutate from being called twice!

  return isLoading ? (
    <CircularProgress
      color="info"
      style={{
        color: '#24262b',
      }}
    />
  ) : result && !isError ? (
    <VerificationSuccess />
  ) : (
    <VerificationFailure />
  );
}

const VerificationSuccess = () => {
  return (
    <div className="App">
      <header className="App-header">
        <div className="App-text">
          Tilivahvistus onnistui! <DoneOutlineIcon />
        </div>
        <br />
        <div className="App-text-2">
          Kiitos. Olemme vastaanottaneet tilivahventeesi. Olemme sinuun yhteydessä kun olemme käsitelleet asian. Voit
          nyt sulkea selaimesi.
        </div>
      </header>
    </div>
  );
};

const VerificationFailure = () => {
  return (
    <div className="App">
      <header className="App-header">
        <div className="App-text">
          Tilivahvistus epäonnistui! <CancelIcon />
        </div>
        <br />
        <div className="App-text-2">Ota yhteyttä Finance Linkin asiakastukeen (09 7744 740).</div>
      </header>
    </div>
  );
};
