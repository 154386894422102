const baseAddress = process.env.REACT_APP_BASE_ADDRESS || window.location.origin;

export const GET_BANK_LIST = `${baseAddress}/api/Bank`;

export const SELECT_BANK = `${baseAddress}/api/Bank/AuthorizeUser`;

export const VALIDATE_ACCOUNT = `${baseAddress}/api/Bank/AccountValidation`;

export const PROCESS_CREATION = `${baseAddress}/api/Admin/AddProcess`;

export const GET_PROCESSES_BY_COMPANY_NAME = (companyName: string) =>
  `${baseAddress}/api/Admin/GetProcesses?companyName=${companyName}`;