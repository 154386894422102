import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { GET_BANK_LIST } from '../constants/Urls';
import axios from 'axios';
import { CircularProgress } from '@mui/material';
import { BankData2 } from '../data/BankData';
import BankList from './BankList';

export default function BankSelectionPage() {
  const fetchBanks = (): Promise<BankData2[]> => axios.get(GET_BANK_LIST).then((response) => response.data);

  const { isLoading, error, data } = useQuery(['banks'], fetchBanks);

  return (
    <div className="App">
      <header className="App-header">
        <div className="App-text">Tervetuloa Finance Linkin tilivahvennepalveluun.</div>
        <br />
        <div className="App-text-2">
        EU:n Rahanpesulain mukaisesti meillä on velvollisuus tarkistaa, että annetut tilinumerot täsmäävät yrityksenne tietojen kanssa.
        <br />
        Kävisittekö ystävällisesti vielä vahvistamassa tilinumeronne oman pankkisi verkkopalvelussa.
        <br/><br/>
        Tarjoamme tilivahvennepalvelun yhteistyössä Enable Bankingin kanssa, joka on Finanssivalvonnan valvoma ja rekisteröity tilitietojen palveluntarjoaja.

 
        </div>
        <br />
        {!isLoading ? <BankList data={data} /> : <CircularProgress color="info" style={{ color: '#24262b' }} />}
      </header>
    </div>
  );
}
