import React from 'react';
import './App.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import BankSelectionPage from './components/BankSelectionPage';
import PageHeader from './components/PageHeader';
import PageFooter from './components/PageFooter';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import VerificationPage from './components/VerificationPage';


const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <PageHeader />
      <BrowserRouter>
        <Routes>
          <Route path="/verification" element={<VerificationPage />} />
          <Route path="/" element={<BankSelectionPage />} />
        </Routes>
      </BrowserRouter>
      <PageFooter />
    </QueryClientProvider>
  );
}

export default App;
