import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import logo from '../images/FL_logo.png';
import { Button, Grid, Stack } from '@mui/material';
import { BankData2 } from '../data/BankData';
import useBankSelect from '../hooks/useBankSelect';
import { useState } from 'react';

interface BankListProps {
  data: BankData2[] | undefined;
}

export default function BankList(props: BankListProps) {
  const { data } = props;

  const [parsedId, setParsedId] = useState<string>('');
  const [parsedCompanyName, setParsedCompanyName] = useState<string>('');

  const search = window.location.search;
  const params = new URLSearchParams(search);

  const id = params.get('id');
  const companyName = params.get('companyName');

  React.useEffect(() => {
    if (id && companyName) {
      setParsedId(id);
      setParsedCompanyName(companyName);
    }
  }, [id, companyName]);

  const [mutate, isLoading, isError, result] = useBankSelect();

  const onClick = (name: string, country: string) => {
    console.log(name, country);
    mutate({ name, country, id: parsedId, companyName: parsedCompanyName });
  };

  return (
    <Box
      sx={{ flexGrow: 1 }}
      style={{ borderStyle: 'solid', borderColor: 'black', margin: '100px', textAlign: 'center', width: '50%' }}
    >
      <Grid container /*spacing={{ xs: 2, md: 2 }}*/ columns={{ xs: 2, sm: 2, md: 8 }} className="App-text-2">
        {data?.map((bank: BankData2, index: number) => {
          return (
            <Grid item xs={2} sm={4} md={4} key={index}>
              <div>
                <Button
                  sx={{
                    '&:hover': {
                      backgroundColor: 'lightblue',
                      color: '#3c52b2',
                    },
                    margin: '20px',
                  }}
                  onClick={() => {
                    onClick(bank.name, bank.country);
                  }}
                >
                  <Stack spacing={2} direction="column">
                    <div style={{ textAlign: 'left' }}>
                      {bank.name} {bank.country}
                    </div>
                    <img src={bank.logo + '-/resize/160x/-/crop/180x60/center/'} />
                  </Stack>
                </Button>
              </div>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
